import React from 'react';
import classNames from 'classnames';
import HTML5Player from './HTML5Player';
import styles from './HTML5Player.scss';
import { isMobile } from '../../commons/detectMobile';

export default class HTML5VideoPlayer extends HTML5Player {
	fullscreen = () => {
		if (this.playerRef.current) {
			const requestFullScreen =
				this.playerRef.current.requestFullscreen ||
				this.playerRef.current.mozRequestFullScreen ||
				this.playerRef.current.webkitRequestFullScreen ||
				this.playerRef.current.msRequestFullscreen;
			if (requestFullScreen) {
				requestFullScreen.bind(this.playerRef.current)();
			}
		}
	};

	render = () => {
		return (
			<video
				data-class="html5videoplayer"
				className={classNames(styles.html5videoplayer)}
				ref={isMobile() ? null : this.playerRef}
				playsInline={true}
				controls={isMobile() || this.state.fullscreen ? 'controls' : false}
				controlsList="nodownload"
				poster={isMobile() ? this.props.poster : ''}
			>
				<source src={this.props.sources.standard} type="video/mp4" />
			</video>
		);
	};
}
