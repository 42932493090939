import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';
import styles from './ContentElementMediaPlayer.scss';
import YouTubePlayer from './YouTubePlayer';
import SeekControl from './SeekControl';
import numeral from 'numeral';
import HTML5VideoPlayer from './HTML5VideoPlayer';
import HTML5AudioPlayer from './HTML5AudioPlayer';
import AutoAspectImage from '../AutoAspectImage/AutoAspectImage.js';
import VolumeControl from './VolumeControl';
import Immutable from 'immutable';
import idx from 'idx';
import { stylesForQueryString } from '../../commons/utils/index';
import trackEvent from '../../commons/tracking/trackers';
import { isMobile } from '../../commons/detectMobile';
import SvgPencil from './pencil.svg';
import SvgEnableFullscreen from './icon_b3_player_fullscreen_enable.svg';
import SvgPlayerPause from './icon_b3_player_pause.svg';
import SvgPlayerPlay from './icon_b3_player_play.svg';
import { parseYoutubeUrl } from '../../commons/utils';
import { isServiceActive, Services, registerListener, removeListener } from '../../commons/dataPrivacy';
import DataPrivacyBanner from '../DataPrivacyBanner/DataPrivacyBanner';

const HIDE_CONTROLS_TIMER = 5000;

/**
 * Component for displaying audio and video content in the webapp and for editing them in the cms.
 */
class ContentElementMediaPlayer extends React.Component {
	static propTypes = {
		poster: PropTypes.string,
		title: PropTypes.string,
		ratio: PropTypes.number,
		headline: PropTypes.string,
		data: PropTypes.object,
		onChange: PropTypes.func,
		isTeaser: PropTypes.bool,
		onTeaserImageChange: PropTypes.func,
		onHeadlineChange: PropTypes.func,
		onTitleChange: PropTypes.func,
	};

	static defaultProps = {
		data: {
			element_type: 'media',
			element_subtype: 'video/youtube',
			metadata: {
				tags: [],
				title: '',
				copyright: '',
			},
		},
	};

	static defaultState = {
		playerLoaded: false,
		playing: false,
		playerAlreadyUsed: false,
		position: 0,
		duration: 0,
		buffering: false,
		error: false,
		title: '',
		src: [],
		controlsHidden: false,
		isDialogOpen: false,
		isYoutubeAllowed: false,
	};

	state = ContentElementMediaPlayer.defaultState;

	containerRef = React.createRef();
	playerRef = React.createRef();

	UNSAFE_componentWillReceiveProps = () => {
		if (!IS_CMS) {
			this.setState({
				...ContentElementMediaPlayer.defaultState,
				playerLoaded: isMobile() || this.state.playerLoaded,
				isYoutubeAllowed: isServiceActive(Services.YOUTUBE),
			});
		}
	};

	/**
	 * Listener for data privacy youtube allowance.
	 */
	changeIsYoutubeAllowed = value => {
		this.setState({ isYoutubeAllowed: value });
	};

	/**
	 * Handles clicks on close on the data privacy banner.
	 */
	handleDataPrivacyClose = () => {
		if (this.state.playing) {
			this._playPause();
		}
		this.setState({ playerLoaded: false });
	};

	componentDidMount() {
		// FIXED: mobile devices only play media elements if they are linked to a touchevent.
		// This directly loads the player and makes audio playable for mobile devices.
		this.setState({
			playerLoaded: isMobile() || this.state.playerLoaded,
			isYoutubeAllowed: isServiceActive(Services.YOUTUBE),
		});
		registerListener(Services.YOUTUBE, this.changeIsYoutubeAllowed);

		window.addEventListener('pausePlayers', this.onPauseEvent);
	}

	componentWillUnmount = () => {
		if (this.timer) {
			clearTimeout(this.timer);
			this.timer = null;
		}
		removeListener(Services.YOUTUBE, this.changeIsYoutubeAllowed);

		window.removeEventListener('pausePlayers', this.onPauseEvent);
	};

	onChange = (key, value) => {
		const data = Immutable.fromJS(this.props.data);
		this.props.onChange(data.setIn(key.split('.'), value).toJS());
	};

	onPauseEvent = e => {
		if (e.detail.sender !== this && this.state.playing) {
			this._playPause();
		}
	};

	onPositionChange = position => {
		this.setState({
			position: position,
		});
	};

	onPositionManuallyChange = position => {
		this._trackEvent('move');
		this.onPositionChange(position);
	};

	onDurationChange = duration => {
		const shouldTrack = this.state.duration === 0;
		this.setState({
			duration: duration,
		});
		if (shouldTrack) {
			this._trackEvent(this.state.playerAlreadyUsed ? 'play' : 'firstPlay');
			this.setState({
				playerAlreadyUsed: true,
			});
		}
	};

	onVolumeChange = volume => {
		if (this.playerRef.current) {
			this.playerRef.current.setVolume(volume);
		}
	};

	onMediaError = e => {
		// eslint-disable-next-line no-console
		console.error('Video could not be loaded', e);
	};

	onBufferingChange = buffering => {
		if (this.state.buffering !== buffering) {
			this._trackEvent(buffering ? 'buffering' : 'bufferingend');
			this.setState({
				buffering: buffering,
			});
		}
	};

	onPlaybackEnded = () => {
		this._trackEvent('stop');
		this.setState({
			playerLoaded: false,
			playing: false,
			position: 0,
			duration: 0,
		});
	};

	_onMouseMove = () => {
		// show controls if hidden
		if (this.state.controlsHidden) {
			this.setState({
				controlsHidden: false,
			});
		}

		// reset timer
		if (this.timer && !isMobile()) {
			clearTimeout(this.timer);
			this.timer = null;
			this.timer = setTimeout(() => {
				this.setState({ controlsHidden: true });
			}, HIDE_CONTROLS_TIMER);
		}
	};

	_playPause = () => {
		// Filter player in edit mode or without a source
		if (!this._hasSrc() || this.props.onChange) {
			return;
		}

		// pause all other players
		if (!this.state.playing) {
			const evt = document.createEvent('CustomEvent');
			evt.initCustomEvent('pausePlayers', false, false, { sender: this });
			window.dispatchEvent(evt);
		}

		if (!this.state.playing && this.props.isTeaser && !isMobile()) {
			this.timer = setTimeout(() => {
				this.setState({ controlsHidden: true });
			}, HIDE_CONTROLS_TIMER);
		} else {
			if (this.timer) {
				clearTimeout(this.timer);
				this.timer = null;
			}
		}

		if (this.state.duration > 0) {
			if (this.state.playing && this.state.buffering) {
				this.onBufferingChange(false);
			}
			this._trackEvent(this.state.playing ? 'pause' : 'play');
		}

		this.setState({
			playerLoaded: !this.state.playerLoaded && !this.state.playing ? true : this.state.playerLoaded,
			playing: !this.state.playing,
		});
	};

	/**
	 * Sends various user events to the tracker service.
	 */
	_trackEvent = event => {
		const id =
			'player-' +
			(this.props.isTeaser ? this.props.title.toLowerCase() : (idx(this.props, _ => _.data.metadata.title) || '').toLowerCase());
		const videoId = this._isYoutube() ? parseYoutubeUrl(this.props.data.content.source.standard).videoId : this.props.data.id;

		trackEvent('ContentElementMediaPlayer', event, id, {
			subtype: this.props.data.element_subtype,
			id: videoId,
			title: idx(this.props, _ => _.data.metadata.title) || null,
			duration: this.state.duration,
			source: this.props.data.content.source.standard,
		});
	};

	_hasSrc = () => {
		return typeof idx(this.props, _ => _.data.content.source.standard) !== 'undefined';
	};

	_isYoutube = () => {
		if (!this._hasSrc()) {
			return false;
		}
		const type = this.props.data.element_subtype;
		return /\/youtube/.test(type);
	};

	_isVideo = () => {
		if (!this._hasSrc()) {
			return false;
		}
		const type = this.props.data.element_subtype;
		return /^video\//.test(type);
	};

	_isAudio = () => {
		if (!this._hasSrc()) {
			return false;
		}
		const type = this.props.data.element_subtype;
		return /^audio\//.test(type);
	};

	_fullscreen = () => {
		if (this._isVideo() && this.playerRef.current) {
			this.playerRef.current.fullscreen();
		}
	};

	openEditorDialog = () => {
		this.setState({ isDialogOpen: true });
	};

	closeEditorDialog = () => {
		this.setState({ isDialogOpen: false });

		if (this.props.isTeaser) {
			// set teaser_image
			this.props.onTeaserImageChange(idx(this.props, _ => _.data.content.poster_image));
		}
	};

	renderPlayer = () => {
		// Player Editor
		if (this.props.onChange) {
			if (IS_CMS) {
				// eslint-disable-next-line @typescript-eslint/no-var-requires
				const mui = require('@material-ui/core');
				const MediaPlayerEditor = require('./MediaPlayerEditor').default;

				return (
					<div
						className={classNames(styles.slideshowEditor)}
						style={{
							backgroundImage: 'url(' + idx(this.props, _ => _.data.content.poster_image.content.source) + ')',
						}}
					>
						<div className={classNames(styles.editButton)}>
							<mui.Fab color="secondary" onClick={this.openEditorDialog}>
								<SvgPencil />
							</mui.Fab>
						</div>
						<mui.Dialog maxWidth="sm" fullWidth={true} open={this.state.isDialogOpen}>
							<mui.DialogContent>
								<MediaPlayerEditor data={this.props.data} onChange={this.onChange} isTeaser={this.props.isTeaser} />
							</mui.DialogContent>
							<mui.DialogActions>
								<mui.Button color="primary" onClick={this.closeEditorDialog}>
									Fertig
								</mui.Button>
							</mui.DialogActions>
						</mui.Dialog>
					</div>
				);
			}
			return null;
		} else if (this._hasSrc() && this.state.playerLoaded) {
			// YouTube player
			if (this._isYoutube()) {
				const parsedUrl = parseYoutubeUrl(this.props.data.content.source.standard);
				return this.state.isYoutubeAllowed ? (
					<YouTubePlayer
						ref={this.playerRef}
						videoId={parsedUrl.videoId}
						startTime={parsedUrl.startTime}
						onPositionChange={this.onPositionChange}
						onDurationChange={this.onDurationChange}
						onBufferingChange={this.onBufferingChange}
						onError={this.onMediaError}
						onEnded={this.onPlaybackEnded}
						playing={this.state.playing}
						position={this.state.position}
					/>
				) : (
					<DataPrivacyBanner
						title={this.props.title}
						isVideoPlayer={true}
						service={Services.YOUTUBE}
						onClose={this.handleDataPrivacyClose}
					/>
				);
			}

			// HTML5 video player
			else if (this._isVideo()) {
				return (
					<HTML5VideoPlayer
						ref={this.playerRef}
						sources={this.props.data.content.source}
						poster={this.props.data.content.poster_image.content.source}
						onPositionChange={this.onPositionChange}
						onDurationChange={this.onDurationChange}
						onBufferingChange={this.onBufferingChange}
						onError={this.onMediaError}
						onEnded={this.onPlaybackEnded}
						playing={this.state.playing}
						position={this.state.position}
					/>
				);
			}

			// HTML5 audio player
			else if (this._isAudio()) {
				return (
					<HTML5AudioPlayer
						ref={this.playerRef}
						sources={this.props.data.content.source}
						onPositionChange={this.onPositionChange}
						onDurationChange={this.onDurationChange}
						onBufferingChange={this.onBufferingChange}
						onError={this.onMediaError}
						onEnded={this.onPlaybackEnded}
						playing={this.state.playing}
						position={this.state.position}
					/>
				);
			}
		}
		return undefined;
	};

	renderPoster = () => {
		const content = idx(this.props, _ => _.data.content.poster_image.content) || {};

		if (this.props.onChange && content.source && content.source.indexOf('blob:') === 0) {
			let backgroundCropStyles = {};
			backgroundCropStyles = stylesForQueryString(content.source, this.containerRef.current ? this.containerRef.current.offsetWidth : '');
			return <div className={classNames(styles.poster)} style={backgroundCropStyles} />;
		} else if (idx(this.props, _ => _.data.content.poster_image.content)) {
			return <AutoAspectImage {...content} />;
		}
		return undefined;
	};

	renderLegal = () => {
		if (this.props.onChange) {
			if (IS_CMS) {
				const ReactMediumEditor = require('../ReactMediumEditor/ReactMediumEditor').default;
				return (
					<ReactMediumEditor
						text={idx(this.props, _ => _.data.metadata.copyright) || ''}
						onChange={this.onChange.bind(this, 'metadata.copyright')}
						options={{
							placeholder: { text: 'Copyright' },
							disableReturn: true,
							toolbar: false,
							buttons: ['anchor'],
						}}
					/>
				);
			}
			return null;
		}
		return <div className={classNames(styles.legal)}>{idx(this.props, _ => _.data.metadata.copyright) || ''}</div>;
	};

	renderHeadline = () => {
		if (this.props.onChange && this.props.isTeaser) {
			if (IS_CMS) {
				const ReactMediumEditor = require('../ReactMediumEditor/ReactMediumEditor').default;

				return (
					<ReactMediumEditor
						text={this.props.headline}
						tag={this.props.isTeaser ? 'h2' : 'h4'}
						className={classNames(styles.headline)}
						onChange={this.props.onHeadlineChange}
						options={{
							placeholder: { text: 'Dachzeile' },
							disableReturn: true,
							toolbar: false,
							buttons: ['anchor'],
						}}
					/>
				);
			}
		} else if (this.props.headline && this.props.isTeaser) {
			return this.props.isTeaser ? (
				<h2 className={classNames(styles.headline)}>{this.props.headline}</h2>
			) : (
				<h4 className={classNames(styles.headline)}>{this.props.headline}</h4>
			);
		}
		return undefined;
	};

	renderTitle = () => {
		if (this.props.onChange) {
			if (IS_CMS) {
				const ReactMediumEditor = require('../ReactMediumEditor/ReactMediumEditor').default;
				return (
					<ReactMediumEditor
						tag={this.props.isTeaser ? 'h1' : 'h3'}
						className={classNames(styles.title)}
						text={this.props.isTeaser ? this.props.title : idx(this.props, _ => _.data.metadata.title) || ''}
						onChange={this.props.isTeaser ? this.props.onTitleChange : this.onChange.bind(this, 'metadata.title')}
						options={{
							placeholder: { text: 'Titel' },
							disableReturn: true,
							toolbar: false,
							buttons: ['anchor'],
						}}
					/>
				);
			}
		}
		return this.props.isTeaser ? (
			<h1 className={classNames(styles.title)}>{this.props.title}</h1>
		) : (
			<h3 className={classNames(styles.title)}>{idx(this.props, _ => _.data.metadata.title) || ''}</h3>
		);
	};

	render() {
		const poster = this.renderPoster();
		const player = this.renderPlayer();
		const legal = this.renderLegal();
		const headline = this.renderHeadline();
		const title = this.renderTitle();

		let seekControl;
		if (this._hasSrc() && this.state.playerLoaded) {
			seekControl = (
				<div className={classNames(styles.position)}>
					<span className={classNames(styles.timecode)}>{numeral(this.state.duration * this.state.position).format('00:00')}</span>
					<SeekControl position={this.state.position} onChange={this.onPositionManuallyChange} />
					<span className={classNames(styles.timecode)}>{numeral(this.state.duration).format('00:00')}</span>
				</div>
			);
		}

		const controlStyle = {},
			containerStyle = {};
		containerStyle[styles.controlsHidden] = this.state.controlsHidden && this.state.playing && this._isVideo();

		let aspectRatio;
		if (this._isVideo() && this.state.playerLoaded) {
			// transform to 16:9 container
			aspectRatio = styles.sixteenbynine;
		}

		const hideControlsStyle = {};
		hideControlsStyle[styles.hideControls] = false;
		if (isMobile() && !this._isAudio()) {
			hideControlsStyle[styles.hideControls] = true;
			seekControl = null;
		}

		return (
			<div data-class="mediaPlayer" className={classNames(styles.mediaPlayer)} onMouseMove={this._onMouseMove} ref={this.containerRef}>
				<div data-class="videoPlayer" className={classNames(styles.videoPlayer, this.props.isTeaser && styles.teaserPlayer)}>
					{poster}
					{player}
					<div className={classNames(styles.aspectRatio, aspectRatio)} />
				</div>
				<div className={classNames('titleBox', styles.teaserbox, containerStyle)}>
					<div className={classNames(styles.controls, controlStyle)}>
						<div className={classNames(styles.titlebar)}>
							{!this.props.isTeaser && legal}
							{this.props.isTeaser && headline}
							{title}
						</div>
						{!isMobile() && (
							<div data-class="buttons" className={classNames(styles.buttons, hideControlsStyle, isMobile ? 'mobile' : '')}>
								{this.state.playerLoaded && (
									<>
										<VolumeControl onChange={this.onVolumeChange} />
										<button title="Vollbildmodus aktivieren" onClick={this._fullscreen}>
											<SvgEnableFullscreen className={classNames(styles.button, styles.buttonPlaying)} />
										</button>
									</>
								)}
								<button title={this.state.playing ? 'Player pausieren' : 'Player starten'} onClick={this._playPause}>
									{this.state.playing ? (
										<SvgPlayerPause
											className={classNames(styles.button, styles.playPauseButton, { [styles.playerUnloaded]: !this.state.playerLoaded })}
										/>
									) : (
										<SvgPlayerPlay
											className={classNames(styles.button, styles.playPauseButton, { [styles.playerUnloaded]: !this.state.playerLoaded })}
										/>
									)}
								</button>
							</div>
						)}
					</div>
					{seekControl}
				</div>
			</div>
		);
	}
}

let ExportComponent = ContentElementMediaPlayer;

if (IS_CMS) {
	// eslint-disable-next-line @typescript-eslint/no-var-requires
	const { themeableForCms } = require('../../commons/utils/cms');
	ExportComponent = themeableForCms(ExportComponent);
}

export default ExportComponent;
