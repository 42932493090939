import { ConnectedRouter } from 'connected-react-router';
import React from 'react';
import { Frontload, frontloadConnect } from 'react-frontload';
import { hot } from 'react-hot-loader/root';
import { Provider } from 'react-redux';
interface IProps {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	store: any;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	history: any;
}

class Root extends React.Component<IProps> {
	buildWrapper = () => {
		if (IS_CMS) {
			return require('./cms/helpers').renderWrapper;
		}
		return ({ children }: { children: React.ReactNode }) => <>{children}</>;
	};

	buildAppComponent = () => {
		const { store } = this.props;
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		let app: any;
		if (IS_CMS) {
			app = require('./cms/Cms').default;
		} else {
			app = require('./app/App').default;
		}

		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		return frontloadConnect(() => app.fetchData(store.getState, store.dispatch).catch((err: any) => console.error(err)), {
			onMount: true,
			onUpdate: false,
		})(app);
	};

	render() {
		const Wrapper = this.buildWrapper();
		const AppComponent = this.buildAppComponent();

		return (
			<Wrapper>
				<Provider store={this.props.store} key="provider">
					<Frontload noServerRender={__DISABLE_SSR__}>
						<ConnectedRouter history={this.props.history}>
							<AppComponent />
						</ConnectedRouter>
					</Frontload>
				</Provider>
			</Wrapper>
		);
	}
}

export default hot(Root);
